import React from 'react';
import "./style.css";
const Success = () => {
  return (
    <div className="container">
      <div class="payment-success">
        <div class="body">
          <h2 class="title">Payment Successful</h2>
          <img
            class="main-img"
            src="https://res.cloudinary.com/dw1zug8d6/image/upload/v1542777688/group-3_3x.png"
            alt=""
          />
          <p>
            Your payment was successful! You can <br />
            now continue using {"{Our Application}"}.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Success;
