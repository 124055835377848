import { Route, Routes, useSearchParams } from 'react-router-dom';
import './App.css';
import Success from './pages/success';
import Failed from './pages/failed';
import { useEffect } from 'react';
import axios from 'axios';

function App() {
  const [params] = useSearchParams();

  const retriveData = async () => {
    try {
      const retriveResponse = await fetch(
        "https://backend-payment-getaway.vercel.app/retrive",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sessionID: params?.get("sessionID") }),
        }
      );

      if (!retriveResponse.ok) {
        throw new Error("Failed to retrieve payment information");
      }

      const paymentInfo = await retriveResponse.json();

      if (paymentInfo?.id) {
        const axios = require('axios');

        // Assuming params and paymentInfo are defined somewhere in your code
        const user_id = params?.get("user_id");
        const type = params?.get("type");
        const money_payed = paymentInfo?.amount_total / 100;
        const payment_status = paymentInfo?.payment_status;

        fetch(
          `https://learngoearn.app/api/LearnEarn/user/payment/add_payment.php?user_id=${user_id}&status=${payment_status}&type=${type}&money_payed=${money_payed}&session_id=${params?.get(
            "sessionID"
          )}`
        )
          .then((response) => {
            response.json();
          })
          .then((res) => console.log(res))
          .catch((error) => {
            console.error(error);
          });

        // await fetch(
        //   "https://blackqnet.com/api/LearnEarn/payment/add_payment.php",
        //   {
        //     body: JSON.stringify({
        //       user_id: params?.get("user_id"),
        //       status: paymentInfo?.payment_status,
        //       type: params?.get("type"),
        //       money_payed: paymentInfo?.amount_total / 100,
        //     }),
        //     method: "POST",
        //     headers: {
        //       "Content-type": "application/json",
        //     },
        //   }
        // );
      }
    } catch (error) {
      console.error("Error handling payment:", error);
    }
  };

  useEffect(() => {
    if (params?.get("sessionID")) {
      retriveData();
    }
  }, [params?.get("sessionID")]);
  return (
    <>{params?.get("page_type") == "success" ? <Success /> : <Failed />}</>
  );
}

export default App;
